.layout-login {
    width: 100%;
    height: 100vh;
    background: linear-gradient(#183861, #08B1C0);
}
.btn-login-gr {
    padding-top: 100px;
}
.home-page .list-team{
    position: relative;
}

.home-page .list-team .item-team {
    position: absolute;
    transition: all .5s;
    height: 180px;
}

.home-page .list-team .item-team.top-1{
    left: 13%;
    top: 0;
}
.home-page .list-team .item-team.top-2{
    left: 38%;
    top: 0;
}
.home-page .list-team .item-team.top-3{
    left: 63%;
    top: 0;
}
.home-page .list-team .item-team.top-4{
    left: 0;
    top: 220px;
}
.home-page .list-team .item-team.top-5{
    left: 25%;
    top: 220px;
}
.home-page .list-team .item-team.top-6{
    left: 50%;
    top: 220px;
}
.home-page .list-team .item-team.top-7{
    left: 75%;
    top: 220px;
}

.trangchu {
    background-image: url('http://localhost:3000/img/texture.png');
    background-size: cover;
    height: 100vh;
    overflow: auto;
}

@media only screen and (max-width: 300px) {
    .trangchu .item-team {
        width: 100%;
    }
}